import React from 'react'
import { Layout, Menu, Card, Avatar, message } from 'antd'
import { useHistory } from 'react-router'
import { IRouteProps } from '@/routes/config'
import { config } from '@/config'
import { getAuth } from '@/utils'
import './index.less'

const { Sider, Header, Content, Footer } = Layout

export interface ILayoutProps {
  menus: IRouteProps[]
}

export function MainLayout(props: React.PropsWithChildren<ILayoutProps>) {

  const auth = getAuth()
  const { menus } = props
  const history = useHistory()
  const year = new Date().getFullYear()
  const signOut = () => {
    auth.signOut().then(() => {
      message.info('退出成功')
    })
  }
  // const Login = () => {
  //   history.push('/login')
  // }
  // const getButton = () => {
  //   const loginState: any = auth.hasLoginState()
  //   if(!!loginState && loginState.isCustomAuth){
  //     return (
  //       <a onClick={signOut} style={{flex:1,textAlign:"right"}}>
  //         退出
  //       </a>
  //     )
  //   } else{
  //     return (
  //       <a onClick={Login} style={{flex:1,textAlign:"right"}}>
  //         登陆
  //       </a>
  //     )
  //   }
  // }

  
  return (
    <div className="App">
      <Layout style={{height:"100%",maxWidth:"600px",margin:"auto"}}>
       
        <Content style={{ display: 'flex', flexDirection: 'column' }}>
          <Layout style={{ flex: '1 1 auto', background: '#fff' }}>
            <Content >
              {props.children}
            </Content>
          </Layout>
        </Content>
        {/* <Footer className="footer" style={{ textAlign: 'center' }}>
          &copy; 里昂（北京）科技有限公司<br/>
            京ICP备20014322号
        </Footer> */}
      </Layout>
    </div>
  )
}
