import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Button, Input, Alert, message, Form } from 'antd'
import { getApp, getAuth } from '@/utils'
import { config } from '@/config'
import { PageHeader } from '@/components'

const { Search } = Input

const ticketUrl = `https://${config.envId}.service.tcloudbase.com/login`
const extSms = require('@cloudbase/extension-sms')




let phone: any, smsCode: any, _smsTimeout: number = 0
type Props = {
  title:string
}
export const Login: React.FC<Props> = ({title}) => {
  const app = getApp()
  const auth = getAuth()
  const { inviterUid } = useParams()
  const db = app.database()

  app.registerExtension(extSms)

  const [smsTimeout, setSmsTimeout] = useState(0)
  const history = useHistory()
  const pattern = /^1[3|4|5|6|7|8][0-9]\d{8}$/

  const testPhone = () => {
    if (!pattern.test(phone)) {
      message.info('手机号错误啦')
      return false
    }
    return true
  }
  const sendSms = async (e: any) => {

    // const phone = e.target.value
    if (smsTimeout != 0) {
      message.info('请等待' + smsTimeout + '秒')
      return
    }
    if (!testPhone()) return

    app.registerExtension(extSms)

    const opts = {
      action: 'Send',
      app,
      phone // 用户输入的手机号
    };

    try {
      await app.invokeExtension(extSms.name, opts); // 发送短信验证码
      _smsTimeout = 60
      setSmsTimeout(60)
      const id = setInterval(() => {
        const st = --_smsTimeout
        console.log(st)
        setSmsTimeout(st)
        if (st <= 0) clearInterval(id)
      }, 1000)
      message.info('短信验证码发送成功')
    } catch (error) {
      message.info('短信验证码发送失败：', error.message)
    }
  }



  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }

  const savePhone = (e: any) => {
    phone = e.target.value
  }
  const saveVerificationCode = (e: any) => {
    smsCode = e.target.value
  }
  const getSmsButtonTxt = () => {
    return smsTimeout == 0 ? ("发送验证码") : (smsTimeout + '秒')
  }
  const smsCodePattern = /^\d{6}$/

  const login = async (e: any) => {

    if (!testPhone()) return
    if (!smsCodePattern.test(smsCode)) {
      message.info('验证码错误')
      return false
    }
    const opts = {
      action: 'Login',
      app,
      phone,
      smsCode
    }
    try {
      await app.invokeExtension(extSms.name, opts)
      const loginState: any = auth.hasLoginState()

      // server
      const res:any = await db.collection('user').where({ phone: loginState.user.customUserId }).field({ regId: true }).get()
      const regId = res.data[0].regId
      if(regId != 0 && inviterUid == loginState.user.customUserId){
        await app.callFunction({
          name: 'inc-inviter-quota',
          data: {
            inviterUid,
            regId
          }
        }).then((res: any) => {})
      }
      localStorage.removeItem('inviterUid')

      if (loginState && loginState.isCustomAuth) history.push('/')
    } catch (error) {
      message.info(error.message)

    }
  }
  useEffect(() => {
    document.title = title
    const loginState: any = auth.hasLoginState()
    if (loginState && loginState.isCustomAuth) history.push('/')
    if(inviterUid) localStorage.setItem('inviterUid',inviterUid)
  }, [])
  return (
    <div>
    <PageHeader />
      <Form style={{width:"100%",padding:"40px",boxSizing:"border-box"}}
        {...layout}
        name="basic"
        initialValues={{
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="手机号"
          name="phone"
          rules={[
            {
              required: true,
              message: '请输入正确手机号',
              pattern: pattern
            },
          ]}
        >
          <Search
            placeholder="请输入手机号"
            enterButton={getSmsButtonTxt()}
            onChange={savePhone}
            onSearch={sendSms}
          />
        </Form.Item>

        <Form.Item
          label="验证码"
          name="smsCode"
          rules={[
            {
              required: true,
              message: '请输入6位数字验证码',
              pattern: smsCodePattern
            },
          ]}
        >
          <Input onChange={saveVerificationCode} />
        </Form.Item>

        <Form.Item >
          <Button type="primary" style={{width:"100%"}} onClick={login}>
            登录（新用户自动注册）
        </Button>
        </Form.Item>
        {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}


      </Form>
    </div>
  )
}
