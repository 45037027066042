import React from 'react'
import {  useHistory } from 'react-router'
import {  Layout ,Avatar} from 'antd'
import { Link } from 'react-router-dom'
const { Footer } = Layout

export const PageFooter: React.SFC<{  }> = () => {
  if(document.domain != 'leon.luxe') return null
  return (
    <Footer className="footer" style={{ textAlign: 'center' }}>
          联系我们<br/>
          手机：13206699399<br/>
          地址：哈尔滨市龙江大厦2层<br/>
          &copy; 哈尔滨曼殊科技有限公司<br/>
            黑ICP备18007771号
        </Footer>
  )
}
