import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Card, Timeline, Divider } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons';
import { getApp, getAuth } from '@/utils'
import { Link } from 'react-router-dom'
import { PageHeader, PageFooter } from '@/components'



const { Meta } = Card;

type Props = {
  title: string
}

export const Home: React.FC<Props> = ({ title }) => {
  const app = getApp()
  const auth = getAuth()
  const db = app.database()
  const history = useHistory()



  return (
    <>
      <Card
        cover={<img alt="Elon Musk" style={{borderRadius:0}} src="./elonmusk.jpg" />}
        style={{borderTop:0}}
      >
        <div style={{position:"relative"}}>
          <div style={{position:"absolute",bottom:"50px",left:"10px" ,color:"#fff",fontSize:"30px"}}>No, I don’t ever give up.<br/>
      I’d have to be dead or completely incapacitated.</div>
        </div>
        <Divider>Elon Musk</Divider>
        <p>The strongest earthman in history,has made mankind a multi planet species.</p>
        <Divider>On sale:</Divider>
      <Divider>ElonMusk.me</Divider>
      <div style={{textAlign:'center'}}>
      The best Elon Musk personal website domain.<br/>
        $ 2 million<br/><br/>
        </div>

        <Divider>Companies founded by Elon Musk</Divider>

        <Timeline mode={"left"}>
          <Timeline.Item label="1995">Zip2</Timeline.Item>
          <Timeline.Item label="In March 1999">X.com and <a target="_blank" href="https://www.paypal.com" >PayPal</a></Timeline.Item>
          <Timeline.Item label="2001"><a target="_blank" href="https://www.spacex.com" >SpaceX</a></Timeline.Item>
          <Timeline.Item label="July 2003"><a target="_blank" href="https://www.tesla.com" >Tesla</a></Timeline.Item>
          <Timeline.Item label="2006"><a target="_blank" href="https://www.solarcity.com" >SolarCity</a></Timeline.Item>
          <Timeline.Item label="August 12, 2013"><a target="_blank" href="https://www.hyperloop-one.com" >HyperLoop</a></Timeline.Item>
          <Timeline.Item label="December 2015"><a target="_blank" href="https://openai.com" >OpenAI</a></Timeline.Item>
          <Timeline.Item label="2016"><a target="_blank" href="https://Neuralink.com" >Neuralink</a></Timeline.Item>
          <Timeline.Item label="December 17, 2016"><a target="_blank" href="https://www.boringcompany.com" >The Boring Company</a></Timeline.Item>
        </Timeline>
      <Divider>School founded by Elon Musk</Divider>
      <Timeline mode={"left"}>
        <Timeline.Item label="2014"><a target="_blank" href="https://adastra.school" >Ad Astra</a></Timeline.Item>
      </Timeline>
      <Divider>Foundation founded by Elon Musk</Divider>
      <Timeline mode={"left"}>
        <Timeline.Item label="2002"><a target="_blank" href="https://www.muskfoundation.org" >Musk Foundation</a></Timeline.Item>
      </Timeline>
      <Divider>The planet colonized by Elon Musk</Divider>
      <Timeline mode={"left"}>
      <Timeline.Item dot={<ClockCircleOutlined className="timeline-clock-icon"/>} color="red" label="2025">Mars</Timeline.Item>
      </Timeline>
      <Divider>Children</Divider>
      <Timeline mode={"left"}>
        <Timeline.Item label="2002">Nevada Alexander Musk</Timeline.Item>
        <Timeline.Item label="2004">Griffin and Xavier</Timeline.Item>
        <Timeline.Item label="2006">Kai, Saxon, and Damian</Timeline.Item>
        <Timeline.Item label="May 4, 2020">X AE A-XII</Timeline.Item>
      </Timeline>
      <Divider>Spouse</Divider>
      <Timeline mode={"left"}>
        <Timeline.Item label="2000 - 2008">Justine Wilson</Timeline.Item>
        <Timeline.Item label="2008 - 2012,2013 - 2016">Talulah Riley</Timeline.Item>
      </Timeline>
      <Divider>Partner</Divider>
      <Timeline mode={"left"}>
        <Timeline.Item label="May 7, 2018">Grimes</Timeline.Item>
      </Timeline>
      <Divider>Parents</Divider>
      <div style={{textAlign:'center'}}>
        <p>Maye Musk (mother)</p>
        <p>Errol Musk (father)</p>
      </div>
      <Divider>Relatives</Divider>
      <div style={{textAlign:'center'}}>
        <p>Kimbal Musk (brother)</p>
        <p>Tosca Musk (sister)</p>
        <p>Lyndon Rive (cousin)</p>
        </div>
      
      <Divider>Who needs to buy</Divider>
      1、If you are Elon Musk's wife, girlfriend or future wife, girlfriend, or best friend, investor, children, this is the best gift for Elon Musk.<br/>
      2、If you are Elon Musk himself.
      <Divider>Contact</Divider>
      <div style={{textAlign:'center'}}>
      237815133<span>@</span>qq.com<br/>
      <img  style={{width:"200px"}} src="./me.jpg"/><br/>
      My WhatsApp<br/><br/><br/><br/>

      <img  style={{width:"200px"}} src="./group.jpg"/><br/>
      <a target="_blank" href="https://chat.whatsapp.com/IiDMCcBenuu91W1TPKbAVM">Join WhatsApp Group</a><br/>
      ElonMusk.me WhatsApp Group<br/><br/><br/><br/>
      <img  style={{width:"200px"}} src="./wx.jpg"/><br/>
      My WeChat<br/>
      </div>
    <br/>
      <Divider>I believe Elon Musk since 2012</Divider>
      <Divider>Elon Musk:Fight for the light of consciousness.</Divider>
      <Divider>Music</Divider>
      <Divider>Don't Doubt Ur Vibe - Elon Musk</Divider>
      </Card>

      {/* <Timeline.Item label="March 30, 2019">RIP Harambe</Timeline.Item>
        <Timeline.Item>stankmemes.com</Timeline.Item>  Musk Entertainment*/}
    </>
  )
}
