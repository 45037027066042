import { config } from '@/config'
import cloudbase from "@cloudbase/js-sdk";

export const getSdk = () => {
  const app = cloudbase.init({
    env: config.envId
  })
  const auth =  app.auth({
    persistence: 'local'
  })
  return {app,auth}
}
export const getApp = () => {
  const {app} = getSdk()
  return app
}
export const getAuth = () => {
  const {auth} = getSdk()
  
  return auth
}
