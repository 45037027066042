import { Result } from 'antd'
import React from 'react'
import { PageHeader } from '@/components'


type Props = {
  title: string
}

export const NoFoundPage: React.FC<Props> = ({ title }) => (
  <div>
    <PageHeader />
    <Result
      status="404"
      title="404"
      subTitle={title}
      extra={<span>点击顶部回到首页</span>}
    />
  </div>
)

