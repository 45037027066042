import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd'
import { HashRouter } from 'react-router-dom'
import { RenderRoutes, appRoutes } from './routes'
import { MainLayout } from './layout'
import { getAuth } from '@/utils'
import 'antd/dist/antd.css'
import './App.less'

const menus = appRoutes.filter((item) => item.menu)

function App() {
  return (
    <div className="App">
      <MainLayout menus={menus}>{ RenderRoutes()}</MainLayout>
    </div>
  )
}

export default () => {
  return (
    <HashRouter>
      <App />
    </HashRouter>
  )
}
