import React, { useState,useEffect}  from 'react'
import {  useHistory } from 'react-router'
import {  Layout ,Avatar,Button} from 'antd'
import { Link } from 'react-router-dom'
import { getAuth } from '@/utils'
const { Header } = Layout

export const PageHeader: React.SFC<{}> = () => {
  const [isLogin, setIsLogin] = useState(false)

  const history = useHistory()
  const auth = getAuth()
  const loginState: any = auth.hasLoginState()
  

  const getBaiduInfo = ()=>{
    if(document.domain != 'leon.luxe') return null
    return (
      <div style={{flex:1,textAlign:"right"}}>
        <Link to="contact" style={{marginRight:10}}>联系我们</Link>
        <Link to="about" >关于我们</Link><br/>
      </div>
    )
  }
  useEffect(() => {
    setIsLogin(!!loginState && !!loginState.isCustomAuth)
  }, [])

  
  const signOut = ()=>{
    auth.signOut()
    setIsLogin(false)
    
  }
  const login = ()=>{
    history.push('/login')
  }

  return (
    <Header className="header flex" style={{padding:'0 10px'}}>
          <div className="logo">
        
          </div>
          <div className="home">
            <Link to="/" >{history.location.pathname == '/'?'泛大陆听书 | fandalu.com':'回首页'}</Link>
          </div>
          {/* {getBaiduInfo()} */}
          <Button style={{height:"64px",flex:1,textAlign:"right"}} type="link" onClick={isLogin?signOut:login} >{isLogin ?'退出':'登录'}</Button>
        </Header>
  )
}
